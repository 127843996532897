export default {
  data() {
    return {
      titlecss: 'lefywidd innertext floatleft systemfont',
      contcss: 'innertext floatright systemfont',
      tempwidth: document.documentElement.clientWidth + 'px',
      hrwidth: document.documentElement.clientWidth - 30 + 'px',
      InvoiceAmount: '',
      InvoiceDatetime: '',
      InvoiceType: ''
    };
  },
  mounted() {
    this.InvoiceAmount = this.$store.state.invoiceRequestAmount;
    this.InvoiceDatetime = this.$store.state.invoiceRequestDatetime;
    this.InvoiceType = this.$store.state.invoiceRequestInvoiceType;
  }
};